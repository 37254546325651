@import '../../variables';

.panel {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(#000000, .1), 0 1px 2px 0 rgba(#000000, .06);
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    &__header {
        border-bottom: 1px solid #E2E8F0;
    }

    &__title {
        margin-bottom: 0;
        line-height: 1;
        color: #2D3748;
        font-size: 14px;
        padding: 15px;
        font-weight: 500;
        display: flex;
        justify-content: center;
    }

    &__badge {
        background-color: #cec8e2;
        padding: 5px 10px 5px 32px;
        border-radius: 15px;
        margin-left: 15px;
        font-size: 12px;
        color: #3A1772;
        line-height: 13px;
        position: relative;
        height: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: -6px;
        margin-bottom: -5px;

        button {
            border: none;
            padding: 0;
            background-color: transparent;
            display: inline-block;
            cursor: pointer;
            vertical-align: middle;
            margin: 0 10px 0 0;
            font-size: 0;
            position: absolute;
            top: 5px;
            left: 8px;
            appearance: none;

            & > svg {
                width: 16px;
                height: 16px;
                fill: #3A1772;
                opacity: .65;
                transition: opacity .2s ease;
            }

            &:hover, &:focus {
                & > svg {
                    opacity: 1;
                }
            }
        }
    }

    &__controls {
        display: flex;
        margin-left: auto;
    }

    &__control {
        border: none;
        padding: 14px 15px 12px 15px;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        font-size: 14px;
        display: block;
        border-left: 1px solid #E2E8F0;
        text-decoration: none;
        text-align: center;
        display: flex;
        align-items: center;
        color: #718096;
        border-bottom: 2px solid transparent;
        text-align: center;
        flex: 1 1 auto;
        justify-content: center;
        border-top: 1px solid #E2E8F0;
        background-color: transparent;

        &:hover, &:focus {
            color: #2D3748;
        }

        &:last-child {
            border-top-right-radius: 3px;
        }

        &--active {
            &, &:hover, &:focus {
                color: #3A1772;
                background-color: rgba(#3A1772, .05);
                border-bottom: 2px solid #806FAD;
            }
        }

        svg {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
    }

    &__header, &__footer {
        flex: 0 0 auto;
    }

    &__footer {
        border-top: 1px solid #E2E8F0;
        font-size: 12px;
        padding: 10px 15px;
        line-height: 1;
        color: #718096;
    }

    &__body {
        flex: 1 1 auto;
        position: relative;
        &-inner {
            padding: 15px;
            height: 100%;
        }
    }

    & + .panel {
        margin-top: 20px;
    }

    @media screen and (min-width: $breakpoint-condense) {
        &__header {
            display: flex;
        }
        &__control {
            border-top: none;
        }
        &__body {
            position: relative;
            &-inner {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: auto;
            }
        }
    }
}
