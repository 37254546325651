.map {
    position: relative;
    height: 100%;

    &__caption {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(#ffffff, .5);
        font-size: 14px;
        line-height: 1;
        padding: 5px;
        border-radius: 2px;
    }
}

.region-list {
    &__button {
        display: block;
        width: 100%;
        text-align: left;
        border: none;
        background-color: transparent;
        padding: 8px 0;
        border-top: 1px solid #E2E8F0;
        color: #4A5568;
        cursor: pointer;
        transition: padding-left .175s ease, color .35s ease;
        position: relative;

        &:hover, &:focus {
            color: #3A1772;
            padding-left: 15px;
        }

        &:first-child {
            border-top: none;
        }

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%233A1772' d='M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z'%3E%3C/path%3E%3C/svg%3E");
            content: '';
            display: inline-block;
            width: 5px;
            height: 10px;
            vertical-align: middle;
            position: absolute;
            margin-right: 7px;
            opacity: 0;
            top: 50%;
            left: 0;
            transform: translate(-10px, -50%);
            transition: transform .25s ease;
        }

        &--active + & {
            border-top: none;
        }

        &--active {
            &, &:hover, &:focus {
                border-bottom: 1px solid rgba(#3A1772, .2);
                border-top: 1px solid rgba(#3A1772, .2);
                background-color: rgba(#3A1772, .05);
                color: #3A1772;
                padding-left: 25px;

                &::before {
                     opacity: 1;
                     transform: translate(10px, -50%);
                 }
            }
        }
    }
}