@import '../../variables';

.stat {
    padding: 15px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    flex: 1 1 50%;


    &:not(:first-child) {
        border-left: 1px solid #E2E8F0;
    }

    &__label, &__value {
        flex: 0 0 auto;
    }

    &__label {
        font-size: 12px;
        margin-bottom: 0;
        line-height: 1.3;
        color: #718096;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    &__value {
        font-size: 54px;
        font-weight: 200;
        color: #2D3748;
        margin-bottom: 5px;
        line-height: 1;

        span {
            font-size: 60%;
            font-weight: 300;
            margin-left: 5px;
        }
    }

    @media screen and (min-width: $breakpoint-condense) {
        &:not(:first-child) {
            border-left: none;
            border-top: 1px solid #E2E8F0;
        }
    }
}
