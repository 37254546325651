@import 'variables';
@import '~normalize.css';

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.65;
    font-size: 16px;
    color: #4A5568;
    background-color: #E2E8F0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, p, ol, ul {
    margin-top: 0;
}

h1, h2, h3, h4 {
    margin-top: 0;
    line-height: 1.1;
}

*:focus {
    box-shadow: none;
    outline: 1px dashed #806FAD;
    outline-offset: 2px;
    position: relative;
}