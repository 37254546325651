@import '../../variables';

.header {
    padding: 25px 25px 0 25px;

    &__content {
        margin-right: 20px;
        color: #546377;

        a {
            color: #546377;
        }
    }

    h1, p {
        margin-bottom: 0px;
    }

    p {
        font-size: 14px;
    }
    
    h1 {
        font-weight: 300;
        color: #2D3748;
    }

    &__logo {
        margin-bottom: 20px;
    }

    @media screen and (min-width: $breakpoint-stack) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        flex-direction: row-reverse;

        &__content {
            order: 1;
            max-width: 80%;
        }

        &__logo {
            order: 2;
            margin-bottom: 0;
        }
    }
}
