@import '../../variables';

.footer {
    height: 100px;
    padding: 0 25px 25px 25px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #546377;

    p {
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
