@import '../../variables';

.results {
    display: flex;
    flex-direction: column;
    margin: -15px;
    height: calc(100% + 30px);

    &__content {
        padding: 15px;
        flex: 1 1 auto;
    }

    &__description {
        font-size: 14px;
        h3 {
            font-weight: 700;
            font-size: 16px;
            line-height: 1.35;
        }
    }

    &__stats {
        display: flex;
        flex: 0 0 auto;
        margin-bottom: 0;
        list-style: none;
        padding-left: 0;
        border-top: 1px solid #E2E8F0;
    }

    @media screen and (min-width: $breakpoint-condense) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        height: auto;
        flex-direction: row;

        &__stats {
            flex-direction: column;
            flex: 0 0 240px;
            border-left: 1px solid #E2E8F0;
            border-top: none;
        }
    }
}
