@import '../../variables';

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(#E7AE12, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(#E7AE12, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(#E7AE12, 0);
  }
}

.wrapper {

    &__regions, &__results, &__chart {
        opacity: 1;
        transition: opacity .5s ease;
    }

    [class*="--highlight"] {
        animation: pulse 3s linear 2s infinite;
        border-radius: 3px;
    }
    [class*="--dim"] {
        position: relative;
        opacity: .35;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
        }
    }
}

.wrapper {
    padding: 25px;

    .panel {
        height: 100%;
        margin-bottom: 20px;
    }

    @media screen and (min-width: $breakpoint-stack) {
        display: flex;
        flex-wrap: wrap;

        .panel {
            margin-bottom: 0;
        }

        &--has-chart &__regions, &--has-chart &__results {
            margin-bottom: 20px;
        }

        &__regions { flex: 0 0 calc(45% - 10px); margin-right: 10px; }
        &__results { flex: 1 1 calc(55% - 10px); margin-left: 10px; }
        &__chart { flex: 1 0 100%; }
    }

    @media screen and (min-width: $breakpoint-condense) {
        height: 100vh;
        display: grid;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        grid-template-columns: 45% 1fr;
        grid-template-rows: 1fr 1fr;

        &--has-chart &__regions, &--has-chart &__results {
            margin-bottom: 0;
        }

        &__regions {
            grid-area: 1 / 1 / 3 / 2;
            margin-right: 0;
        }

        &__results {
            grid-area: 1 / 2 / 3 / 3;
            margin-left: 0;
        }

        &--has-chart &__results {
            grid-area: 1 / 2 / 2 / 3;
        }

        &--has-chart &__chart {
            grid-area: 2 / 2 / 3 / 3;
        }
    }

    @media screen and (min-width: $breakpoint-condense) and (min-height: 780px) {
        height: calc(100vh - 200px);
    }
}
